/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM725P
 * 화면 설명: 미결명세조회 > 고객정보 > 상세조건 
 * 접근권한: FC, 지점장
 * 작 성 일: 2023.01.17
 * 작 성 자: 서영화 
 */
<template>
  <ur-page-container title="고객정보 상세조건" :show-title="true" type="subpage" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-sch-area pt30 pb80">
        <!-- 조직관련 COMPONENT : 관리자 접근시 버튼 노출 -->
        <SearchCondOrgPM ref="refSearchOrg" v-if="pIsMngr" v-model="lv_SelectOrg" :isFofType="1" :pOrgData="pOrgData725P" @selectResult="fn_SelectCnsltResult"/>
        <!-- 미결사유 mt20 -->
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="">
          <span class="label-title mb10">미결사유</span>
          <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="pendRsnCd.value" class="chip-type-wrap chip-w50" solid primary>
              <mo-segment-button v-for="(item, idx) in pendRsnCds" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- 구분 -->
        <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="mt20 pb20">
          <span class="label-title mb10">구분</span>
          <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="prMaintFlag.value" class="chip-type-wrap" solid primary>
              <mo-segment-button v-for="(item, idx) in prMaintFlags" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_01" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ClickClear">초기화</mo-button>
            <mo-button componentid="mo_button_02" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ClickSearch">조회</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
import SearchCondOrgPM from '@/ui/pm/SearchCondOrgPM.vue'
import pmConstants     from '@/config/constants/pmConstants.js'

export default {

  name: 'MSPPM725P', 
  screenId: 'MSPPM725P', 
  components: {   
    SearchCondOrgPM 
  },//componets,

  props:{
    pPage: String,            // 부모 페이지명
    pIsMngr: Boolean,         // 지점장여부
    pOrgData: {               // 컨설턴트 및 조직정보
      type: Object,
      default: {}
    },
    pPendRsnCd: String,       // 미결사유
    pPrMaintFlag: String,     // 구분 
    pSelectedItems: {         // 조회조건 
      type: Object,
      default: {}
    }

  },//props

  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  data () {
    return {
      lv_ReturnVal: {},        // 리턴값
      isReturn: false,         // 리턴값 세팅여부 

      /** 
       * 사용자 정보 변수
       */ 
      lv_SelectOrg: {},       // 조직 및 담당 컨설턴트 컴포넌트 데이터 
      pOrgData725P: {},
      userInfo: {},           // 사용자정보 
      
      /** 
       * 조회 변수 
       */
      pendRsnCds: [],         // 미결사유 CODE-LIST
      pendRsnCd: {},          // 선택된 미결사유 데이터
      prMaintFlags: [],       // 구분 CODE-LIST
      prMaintFlag: {},        // 선택된 구분 데이터

      selectedItems: {},      // 조회조건 결과

      /**
       * 제어변수
       */
      isCleared: false,       // 조회조건 초기화 여부 

    }
  },//data

  created () {
    window.vue.getStore('progress').dispatch('FULL')              // post 호출 전 Progress 타입 설정.
    
    this.userInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 데이터 설정 

    /**
     * props 데이터 및 공통코드 설정
     */
    this.pOrgData725P   = this.pOrgData
    this.pendRsnCds     = pmConstants.CUST_INFO_PEND_RSN_CD
    this.prMaintFlags   = pmConstants.CUST_INFO_SC_CD

    /**
     * 조회조건 결과 설정 
     */
    if(JSON.stringify(this.pSelectedItems) === '{}'){
      // 초기화 
      // 선택지점, 선택사업부, 선택지역단, 컨설턴트, 미결사유, 구분
      this.selectedItems = {
        hofOrgCd:     {title: '', text: '', idx: 1},                           
        dofOrgCd:     {title: '', text: '', idx: 2},                             
        fofOrgCd:     {title: '', text: '', idx: 3},                              
        cnsltEno:     {title: '컨설턴트', text: '', idx: 4},                       
        pendRsnCd:    {title: '미결사유', text: '', idx: 5},  
        prMaintFlag:  {title: '구분', text: '', idx: 6},   
      }
    }else{
      this.selectedItems    = this.pSelectedItems   
    }

  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
    
    // 조회조건 선택값 설정 
    this.pendRsnCd   = Object.assign({}, this.pendRsnCds.filter((item) => item.value === this.pPendRsnCd)[0])
    this.prMaintFlag = Object.assign({}, this.prMaintFlags.filter((item) => item.value === this.pPrMaintFlag)[0])
    
  },//mounted

  watch: {

    /** 
     * 선택된 미결사유 데이터를 저장한다.
     */
    'pendRsnCd.value': function(val){
      let data =  this.pendRsnCds.filter((item) => item.value === val)
      this.pendRsnCd = Object.assign({}, data[0])
    },

    /**
     * 선택된 구분 데이터를 저장한다.
     */
    'prMaintFlag.value': function(val){
      let data =  this.prMaintFlags.filter((item) => item.value === val)
      this.prMaintFlag = Object.assign({}, data[0])
    },

  },//watch

  computed: {},//computed

  methods: {

    /**
     * @description 조직 및 담당컨설턴트 변경 시, 전역변수에 세팅해준다.
     * @param       {Object} pData 조직 및 담당컨설턴트 정보 
     */
    fn_SelectCnsltResult(pData){
        this.lv_SelectOrg = pData
    },

    /**
     * @description 선택된 조건값을 초기 조건값으로 초기화 한다.
     */
    fn_ClickClear(){
      
      // 관리자 접근 시 조직정보 초기화 후, 재조회
      if (this.pIsMngr) this.$refs.refSearchOrg.fn_GetFofRol()

      this.pOrgData725P   = {}
      this.pendRsnCd      = Object.assign({}, this.pendRsnCds[0])
      this.prMaintFlag    = Object.assign({}, this.prMaintFlags[0])

    },

    /**
     * @description 부모화면에 전달할 리턴 데이터를 세팅해준다.
     */
    fn_SetReturnVal () {
      
      // 초기화
      this.lv_ReturnVal = {} 

      // 조회조건 결과 설정
      this.fn_SetSelectedItem()

      // 리턴값 설정
      this.lv_ReturnVal.cnsltEno = !_.isEmpty(this.lv_SelectOrg.cnslt)  ? this.lv_SelectOrg.cnslt.key  : ''  
      this.lv_ReturnVal.dofOrgCd = !_.isEmpty(this.lv_SelectOrg.dofOrg) ? this.lv_SelectOrg.dofOrg.key : '' 
      this.lv_ReturnVal.fofOrgCd = !_.isEmpty(this.lv_SelectOrg.fofOrg) ? this.lv_SelectOrg.fofOrg.key : '' 
      this.lv_ReturnVal.hofOrgCd = !_.isEmpty(this.lv_SelectOrg.hofOrg) ? this.lv_SelectOrg.hofOrg.key : '' 
      this.lv_ReturnVal.pOrgData = this.lv_SelectOrg 
      
      this.lv_ReturnVal.pendRsnCd     = this.pendRsnCd.value   
      this.lv_ReturnVal.prMaintFlag   = this.prMaintFlag.value 
      this.lv_ReturnVal.selectedItems = this.selectedItems
      this.lv_ReturnVal.isCleared     = this.isCleared   

      // 리턴값 세팅여부
      this.isReturn = true  
 
    },

    /**
     * @description 검색결과 데이터(조건이름)을 저장한다.
     *              초기 조건값과 현재 조건값이 동일하지 않을 때만 저장한다.
     */
    fn_SetSelectedItem(){

      if((this.pendRsnCd.value === this.pendRsnCds[0].value) && (this.prMaintFlag.value === this.prMaintFlags[0].value)){
        
        // 조직 컴포넌트 데이터가 존재할 경우
        if(!_.isEmpty(this.lv_SelectOrg)){
          if(this.userInfo.userId === this.lv_SelectOrg.cnslt.key){
            this.isCleared = true 
            return
          }
        }else{
          this.isCleared = true 
          return 
        }

      }
      
      this.isCleared = false 

      this.selectedItems.hofOrgCd.text  = !_.isEmpty(this.lv_SelectOrg.hofOrg) ? this.lv_SelectOrg.hofOrg.label : '' 
      this.selectedItems.dofOrgCd.text  = !_.isEmpty(this.lv_SelectOrg.dofOrg) ? this.lv_SelectOrg.dofOrg.label : '' 
      this.selectedItems.fofOrgCd.text  = !_.isEmpty(this.lv_SelectOrg.fofOrg) ? this.lv_SelectOrg.fofOrg.label : '' 
      this.selectedItems.cnsltEno.text  = !_.isEmpty(this.lv_SelectOrg.cnslt) ? new Object(this.lv_SelectOrg.cnslt).label.split('(')[0].trim() : ''
      
      this.selectedItems.pendRsnCd.text   = this.pendRsnCd.text
      this.selectedItems.prMaintFlag.text = this.prMaintFlag.text

    },

    /**
     * @description 리턴 데이터 세팅 후, 팝업을 닫는다.
     */
    fn_ClickSearch(){

      // 리턴값 세팅
      this.fn_SetReturnVal() 

      if (this.isReturn) {
        this.$emit('searchPopup', { rtnData: this.lv_ReturnVal });
      }
      
    },
 },// methods

}//export default


</script>