/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM724D
 * 화면 설명: 미결명세조회 > 고객정보 
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.01.17
 * 작 성 자: 서영화 
 */
<template>
<ur-page-container class="msp" :show-title="false" :topButton="false">
  <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp">
    
    <!-- Content영역 start-->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap"> 
      <ur-box-container direction="column" alignV="start" class="ns-list-area msp-list-area pb150"> 
        <ur-box-container v-if="!isNoData" alignV="start" componentid="ur-box-container_002" direction="column" class="ns-check-list type-2">
          <!-- 조회결과 리스트 -->
          <mo-list :list-data="custInfoVO">
            <template #list-item="{ index, item }">
              <mo-list-item expandable prevent-expand :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
                <mo-checkbox v-model="item.checked" @input="fn_ClickCheckPartial" :disabled="isDisabledCheck" :ref="`chk${index}`"/> 
                <div class="list-item__contents">
                  <div class="list-item__contents__title fexTy3">
                    <!-- 계약자명 ... maxW175 -->
                    <span @click.stop="fn_OpenCustInfoBs(item)" class="name">{{ item.name }}</span>
                    <!-- 미결사유 -->
                    <mo-badge :text="item.pendRsnCdNm" class="badge-sample-badge lightblue sm" shape="status">{{ item.pendRsnCdNm }}</mo-badge> 
                  </div>
                  <!-- 생년월일 -->
                  <div class="list-item__contents__info">
                    <span class="fs14rem crTy-bk7 mr10">생년월일</span><span class="crTy-bk1">{{ item.birthdate }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 고객구분 -->
                    <span class="fs14rem mr10">{{ item.clctCnsltYnNm }}</span><em>|</em>
                    <!-- 발생일 -->
                    <span class="fs14rem crTy-bk7 mr10">발생일</span><span class="crTy-bk1">{{ item.pendStndYmd }}</span>
                  </div>
                </div>
                <!-- 아코디언 컨텐츠 start -->
                <template v-slot:expand>
                  <div class="list-item-detail">
                    <div class="contents-row">
                      <span class="dsInline crTy-bk7 fs14rem mr10 min50">자택주소</span><span :class="item.homeAddrStatCdNm == 'N' ? 'crTy-orange2' : 'crTy-bk1'">{{ item.homeAddrStatCdNm }}</span><em>|</em>
                      <span class="dsInline crTy-bk7 fs14rem mr10 min50">직장주소</span><span :class="item.offAddrStatCdNm == 'N' ? 'crTy-orange2' : 'crTy-bk1'">{{ item.offAddrStatCdNm }}</span>
                    </div>
                    <div class="contents-row">
                      <span class="dsInline crTy-bk7 fs14rem mr10 min50">휴대폰</span><span :class="item.mobStatCdNm == 'N' ? 'crTy-orange2' : 'crTy-bk1'">{{ item.mobStatCdNm }}</span><em>|</em>
                      <span class="dsInline crTy-bk7 fs14rem mr10 min50">이메일</span><span :class="item.emailStatCdNm == 'N' ? 'crTy-orange2' : 'crTy-bk1'">{{ item.emailStatCdNm }}</span>
                    </div>
                  </div>
                </template>
                <!-- 아코디언 컨텐츠 end -->

              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>

        <!-- NoData 영역 start -->
        <ur-box-container v-else alignV="start" componentid="ur_box_container_008" direction="column" class="no-data">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <!-- NoData 영역 end -->
        <!-- 데이터 없음 -->

      </ur-box-container>
    </ur-box-container>
    <!-- Content영역 end -->

    <!-- 계약 로딩 표시 -->
    <div class="msp loading--dim msp-in-page" v-show="isReading">
      <div class="preloader-bubbling">
        <span id="bubbling_1"></span>
        <span id="bubbling_2"></span>
        <span id="bubbling_3"></span>
      </div>
      <div class="loading-text-box">
        <span class="title">{{loadingMsg}}</span>
        <span class="txt" v-html="loadingSubMsg" />
      </div>
    </div>

  </ur-box-container>
</ur-page-container>
</template>

<script>

import pmConstants from '@/config/constants/pmConstants'
import MSPPM725P   from '@/ui/pm/MSPPM725P'               // 고객정보 상세조건

export default {
  name: 'MSPPM724D', 
  screenId: 'MSPPM724D', 
  components: {},//componets,

  props:{},//props

  data () {
    return {
      
      title: '고객정보',

      /**
       * 부모화면(MSPPM720M)에 넘겨지는 데이터 
       */
      emitData: {
        isSearched: '',
        searchedItems: [],
        checkedCount: 0,
        totalCount: 0,
        isCleared: true
      },

      lv_inputParam: {},        // 서비스호출 파라미터 

      /**
       * 사용자 정보 변수
       */
      userInfo: {},             // 조직 및 컨설턴트 정보
      isMngr: false,            // 지점장 여부
      pOrgDataMSPPM724D: {},    // 최근 조회조건 데이터 

      /**
       * 화면 변수
       */
      isNoData: false,         
      isLoading: false,
      isReading: false,
      loadingMsg: '고객 정보 조회중',
      loadingSubMsg: '2~3분 정도 소요될 수 있습니다.',

      /**
       * 더보기 key 변수 
       */ 
      pagingAttrs: {
        maxRow: 20,             
        nextPageFlg: ' '	
      },

      /**
       * 체크박스 변수
       */
      isDisabledCheck: false,   // 체크박스 비활성화 여부 (타 컨설턴트 고객 호출 하면 체크박스 비활성화) 
      totalCount: 0,            // 전체 고객수 
      selectedItems: [],        // 선택된 고객 리스트 

      /**
       * 조회 변수
       */
      custInfoVO: [],                                           // 고객정보 조회 데이터 
      pendRsnCd: pmConstants.CUST_INFO_PEND_RSN_CD[0].value,    // 미결사유 
      prMaintFlag: pmConstants.CUST_INFO_SC_CD[0].value,        // 구분 

      searchedItems: {},        // 검색결과 데이터 

      /**
       * 제어 변수 
       */ 
      isSearched: false,        // 조회이력 저장 변수 
      isCleared: false,         // 조회조건 초기화 여부 
      
      /** 
       * 팝업 연동
       */
      popup725: {},             // MSPPM725P 고객정보 상세조건 검색

    }
  },//data

  created () {

    window.vue.getStore('progress').dispatch('FULL')               // post 호출 전 Progress 타입 설정

    this.userInfo = this.getStore('userInfo').getters.getUserInfo  // 사용자 정보

    /**
     * 지점장 여부를 확인한다.
     * 해당 사용자는 본인이 아닌 FC번호로 조회가능하다.
     */
    if(this.$cmUtil.isSrchOrgCombo() === 'Y') this.isMngr = true

  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    /**
     * 홈, 메뉴로 접근할 경우, '미지급액' 화면 호출 
     * 그 이외는 이전에 노출했던 화면 호출 
     */ 
    if(!_.isEmpty(this.$route.params)){
      this.fn_Submit()
      this.getStore('pmStore').dispatch('PRE_SRN_OBJ_PM720M', {})
    }
    else{
      let storeData = this.getStore('pmStore').state.preSrnObjPM720M
      this.fn_Submit()
      // if(!_.isEmpty(storeData)){
      //   this.fn_HandlePmStore('getter')
      // }else{
      //   this.fn_Submit()
      // }
    }

  },//mounted

  watch: {
    
    /**
     * 선택된 데이터를 부모화면에 emit 한다.
     */
    checkedCount(){
      // emit 
      this.fn_SetMSPPM724DData()
    },

  },//watch

  computed: {
        
    /**
     * 선택된 데이터 개수를 구한다.
     */
    checkedCount(){
      return this.custInfoVO.filter(item => item.checked).length
    },
        
    /**
     * 선택된 데이터를 추출한다. 
     */ 
    checkedList(){
      return this.custInfoVO.filter(item => item.checked)
    },

  },//computed

  methods: {
    
    /**
     * @description 초기 조회조건으로 초기화 한다.
     */
    fn_ClearCond(){
      
      this.pOrgDataMSPPM724D = {}                                            
      this.pendRsnCd         = pmConstants.CUST_INFO_PEND_RSN_CD[0].value  
      this.prMaintFlag       = pmConstants.CUST_INFO_SC_CD[0].value        
      this.totalCount        = 0          
      this.isSearched        = false  
      this.searchedItems     = {}           

    },

   /**
     * @description - (현재조건값 === 초기조건값) 뒤로가기를 한다.
     *              - (현재조건값 !== 초기조건값) 조건 초기화 후 조회한다.
     */
    fn_BackToPrevious(){

      if(this.isSearched && !this.isCleared){
        this.fn_ClearCond()
        this.fn_SetMSPPM724DData() // emit : 화면초기화를 위해서 
        this.fn_Submit()
      }else{
        // 메뉴로 진입했으면 홈화면으로 이동한다.
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
          return
        }
        this.$router.go(-1)
      }

    },

   /**
    * @description 부모화면(MSPPM720M)에 데이터를 전달한다.
    */
    fn_SetMSPPM724DData(){

      this.emitData.isSearched    = this.isSearched
      this.emitData.searchedItems = this.searchedItems
      this.emitData.checkedCount  = this.checkedCount
      this.emitData.totalCount    = this.totalCount
      this.emitData.isCleared     = this.isCleared

      this.$emit('setChildData', this.emitData)

    },

   /**
     * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
     * @notice      상세조건 팝업에서 조직 항목 변경(지점, 지역단, 컨설턴트) 후, 정상적인 조회 가능하다.
     *              조직 항목 변경(사업부) 후, 정상적인 조회가 안된다면 VO부터 수정해야한다.
     *              VO에 clmHofOrgNo 필드 존재하지 않는다.
     */
    fn_Submit(){
                  
      // 초기화 
      this.lv_inputParam.reqType   = 'P'
      this.lv_inputParam.pendTypCd = '20'
      this.pagingAttrs = { maxRow: 20, nextPageFlg: ' ' } 
      this.totalCount  = 0  
      this.isNoData    = false
      this.custInfoVO.splice(0)

      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)

      // 조직 및 컨설턴트 데이터 세팅 
      this.lv_inputParam.clmnyCnsltNo = this.userInfo.userId          // 컨설턴트번호 : cnsltNo
      this.lv_inputParam.dofOrgNo     = this.userInfo.onpstDofNo      // 소속지역단조직번호 : dofOrgNo
      this.lv_inputParam.fofOrgNo     = this.userInfo.onpstFofOrgNo   // 소속지점조직번호 : fofOrgNo

      // this.lv_inputParam.clmHofOrgNo  = this.userInfo.onpstHofOrgNo // 소속사업부조직번호 : HofOrgNo
      
      if(this.pOrgDataMSPPM724D.cnslt !== undefined && this.isMngr === true) {
        // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
        if(this.userInfo.userId !== this.pOrgDataMSPPM724D.cnslt.key){
          this.isDisabledCheck = true
        } else {
          this.isDisabledCheck = false
        }
        // 선택된 조직 및 컨설턴트 데이터 세팅 
        this.lv_inputParam.clmnyCnsltNo = this.pOrgDataMSPPM724D.cnslt.key     
        this.lv_inputParam.dofOrgNo     = this.pOrgDataMSPPM724D.dofOrg.key  
        this.lv_inputParam.fofOrgNo     = this.pOrgDataMSPPM724D.fofOrg.key  

        // this.lv_inputParam.clmHofOrgNo  = this.pOrgDataMSPPM711M.hofOrg.key

      }else{
        this.isDisabledCheck = false
      }

      // 조회조건 세팅
      this.lv_inputParam.pendRsnCd   = this.pendRsnCd.trim()    
      this.lv_inputParam.prMaintFlag = this.prMaintFlag.trim()

      // emit : 초기화하기 위해서  
      this.fn_SetMSPPM724DData()

      window.vue.getStore('progress').dispatch('FULL')  // post 호출 전 Progress 타입 설정.

      // 서비스 호출 
      this.fn_GetService()

    },
      
    /**
     * @description 고객정보 목록 조회 서비스 호출한다.
     * @callback    fn_HandleCallback 
     */
    fn_GetService() {
      
      // 더이상 로딩할 데이터 없을 경우 return 
      if (this.pagingAttrs.maxRow === 0 && this.pagingAttrs.nextPageFlg === ' ') return

      // 로딩을 통해 데이터를 가져올 경우, pagingAttrs를 매번 갱신해줘야해서 해당 부분에서 세팅  
      this.lv_inputParam.pagingAttrs = this.pagingAttrs

      const trnstId = 'txTSSPM40S7' 
      const auth    = 'S'

      let pParams = this.lv_inputParam

      this.isLoading = true

      return this.post(this, pParams, trnstId, auth)
       .then(this.fn_HandleCallback)
       .catch(function (error) { window.vue.error(error) })

    },
    
   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){

      let confirm = this.getStore('confirm')

      if(response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003'){

          let responseList = response.body.pendInfoVO  // 고객정보 조회

          if(responseList !== null){

            // 고객정보 조회 데이터 가공 
            responseList.forEach((item, index) => {

              item.pendStndYmd = this.$commonUtil.dateDashFormat(item.pendStndYmd)        
              item.birthdate   = this.$commonUtil.dateDashFormat(item.birthdate)         

              if(this.$bizUtil.isEmpty(item.homeAddrStatCdNm)) item.homeAddrStatCdNm = 'N' 
              if(this.$bizUtil.isEmpty(item.offAddrStatCdNm))  item.offAddrStatCdNm  = 'N' 
              if(this.$bizUtil.isEmpty(item.mobStatCdNm))      item.mobStatCdNm      = 'N' 
              if(this.$bizUtil.isEmpty(item.emailStatCdNm))    item.emailStatCdNm    = 'N' 

              this.$set(responseList[index], 'checked', false)    
              this.$set(responseList[index], 'idx', index)      
              this.$set(responseList[index], 'chnlCustId', '')    
              this.$set(responseList[index], 'custNm', item.name)

              this.custInfoVO.push(responseList[index])

            })

            // 더보기 데이터 저장  
            this.pagingAttrs = response.body.pagingAttrs
            
            // 조회데이터 총 개수 
            this.totalCount = this.custInfoVO.length

          }
        }else{
          confirm.dispatch('ADD', response.msgComm.msgDesc)
      }

      this.isLoading = false 

      // 데이터 유무 
      if (this.custInfoVO.length === 0) {
        this.isNoData = true    
      } else {
        this.isNoData = false  
      }
      
      // emit : 결과값 세팅하기 위해서 
      this.fn_SetMSPPM724DData()
      // pmStore 데이터 저장
      this.fn_HandlePmStore('setter')

    },

    /**
     * @description 조회목록을 부분선택 한다.
     */
    fn_ClickCheckPartial(){

      this.selectedItems = [] // 초기화
      this.selectedItems = this.checkedList

      this.checkedList.forEach(e => {
        e.contNo    = e.whlContNoEnc
        e.contNoEnc = e.whlContNoEnc
        e.custNm    = e.name
      })

      this.checkedList.busnCd = 'PM'

      // 공통 시트를 가리고 사용자 시트를 보여준다.
      this.$cmUtil.setSelectedCustList(this, this.checkedList, this.$attrs.lv_ParentViewId)

    },

    /**
     * @description 체크박스 이외의 영역 클릭했을 때, 체크 활성화 및 비활성화 한다.
     * @param       {Number} index 리스트 데이터의 index
     */
    fn_OnClickListItem(index) {
      
      if(this.isDisabledCheck) return 
      
      this.$refs[`chk${index}`].onClick()
    },

    /**
     * @description 데이터를 로딩한다.
     */
    fn_LoadingData(){

      if (!this.isLoading) {
        window.vue.getStore('progress').dispatch('PART') // post 호출 전 Progress 타입 설정.

        this.fn_GetService()
      }
    },
    

    /**
     * @description 고객정보 상세조건 팝업을 제어한다.
     */
    fn_OpenMSPPM725P(){

      this.popup725 = this.$bottomModal.open(MSPPM725P, {
        properties:{
          pPage: this.$options.screenId,
          pIsMngr: this.isMngr,                     // 지점장 여부
          pOrgData: this.pOrgDataMSPPM724D,         // 최근 조직 및 컨설턴트
          pSelectedItems: this.searchedItems,       // 조회조건 
          pPendRsnCd: this.pendRsnCd,               // 미결사유 
          pPrMaintFlag: this.prMaintFlag,           // 구분
        },

        listeners: {
          searchPopup: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup725)

            this.pendRsnCd         = pData.rtnData.pendRsnCd     
            this.prMaintFlag       = pData.rtnData.prMaintFlag  
            this.pOrgDataMSPPM724D = pData.rtnData.pOrgData
            this.searchedItems     = pData.rtnData.selectedItems
            this.isCleared         = pData.rtnData.isCleared   
            this.isSearched        = true

            // 고객정보 조회 
            this.fn_Submit()

          }
        }
      })
    },

    /**
     * @description 계약자명을 클릭할 때 nameUI를 호출한다.
     * @param       {Object}  item 선택된 미지급액 데이터 
     */
    async fn_OpenCustInfoBs(item){

      let prcContNo  = '',
          cnsltNo    = '',  
          nmUiObject = {}

      // 계약번호 복호화 
      if(!this.$bizUtil.isEmpty(item.whlContNoEnc)) {
        prcContNo  = await this.$commonUtil.getDecryptCall(this, [item.whlContNoEnc]) 
        prcContNo  = prcContNo[0]
      }

      if(this.pOrgDataMSPPM724D.cnslt === undefined || this.pOrgDataMSPPM724D.cnslt === null || this.pOrgDataMSPPM724D.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgDataMSPPM724D.cnslt.key
      }

      nmUiObject = {
        chnlCustId: item.chnlCustId,
        custNm: item.custNm,
        cnsltNo: cnsltNo,
        contNo : prcContNo,
        parentId: this.$attrs.lv_ParentViewId
      }

      this.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject, function (response, dtl){
        return
      })

    },

    /**
     * @description pmStore을 제어한다.
     * @param       {String} type pmStore 핸들러 종류(setter, getter)
     */
    fn_HandlePmStore(type){
      let storeData = {}

      if(type === 'setter'){
        // storeData.lv_inputParam = this.lv_inputParam
        // storeData.pagingAttrs   = this.pagingAttrs
        // storeData.searchedItems = this.searchedItems
        // storeData.custInfoVO    = this.custInfoVO
        storeData.page          = this.$options.screenId
        // storeData.pOrgData      = this.pOrgDataMSPPM724D
        // storeData.isMngr        = this.isMngr
        // storeData.pendRsnCd     = this.pendRsnCd
        // storeData.prMaintFlag   = this.prMaintFlag
        // storeData.prMaintFlag   = this.prMaintFlag
        // storeData.isSearched    = this.isSearched
        // storeData.selectedItems = this.selectedItems

        this.getStore('pmStore').dispatch('PRE_SRN_OBJ_PM720M', storeData)
      }
      else if(type === 'getter'){
        storeData = this.getStore('pmStore').state.preSrnObjPM720M

        // this.lv_inputParam     = storeData.lv_inputParam
        // this.searchedItems     = storeData.searchedItems
        // this.custInfoVO        = storeData.custInfoVO
        // this.totalCount        = storeData.custInfoVO.length
        // this.pOrgDataMSPPM724D = storeData.pOrgData
        // this.isMngr            = storeData.isMngr
        // this.pendRsnCd         = storeData.pendRsnCd
        // this.prMaintFlag       = storeData.prMaintFlag
        // this.isSearched        = storeData.isSearched
        // this.pagingAttrs       = storeData.pagingAttrs
        // this.selectedItems     = storeData.selectedItems

        // this.fn_SetMSPPM724DData()
      }

    },

    /**
     * @description 로딩 메시지를 설정한다.
     * @param       pShowFlag Progress 화면 설정 Flag
     */
    fn_SetProgressMsgPop(pShowFlag) {
      this.isReading = pShowFlag
    },


 },// methods

}//export default


</script>